.Org {
  background-color: rgba(224, 219, 222, 0.725);
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5%;
  display: flex;
  flex-direction: column;
  border: 3px solid rgb(238,59,121);
  padding: 4rem;
  min-height: 100vh;
  width: 80%;
  min-width: 80%;
  margin: 0 auto;
  margin-bottom: 10vh;
  margin-top: 10vh;
  border-radius: 10px;
}



.organizatori {
  color: rgb(238,59,121);
}

body {
  padding-top: 30px;
}

.red {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Tri kolone */
  grid-gap: 10px;
  /* Razmak između slika */
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
}

.prvi-red,
.drugi-red {
  width: 80%;
  margin-bottom: 10px;
}

.prvi-red img,
.drugi-red img {
  width: 100%;
  /* Postavite da slike zauzimaju punu širinu roditeljskog elementa */
  height: auto;
  border-radius: 20px;
  /* margin: 10px; */
  /* width: 150px;
  height: auto;
  margin: 10px; */
  /* Dodaje razmak između slika */
}

.flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10vh;
}

.div-gornji {
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 30px;
  width: 75%;
  border: 3px solid rgb(238,59,121);
  background-color: rgba(224, 219, 222, 0.725);
  padding: 20px;
  /* Dodajte razmak unutar diva */
  margin-bottom: 3rem;
}

.div-donji {
  border-radius: 10px;
  margin: 0 auto;
  width: 75%;
  border: 3px solid rgb(238,59,121);
  background-color: rgba(224, 219, 222, 0.725);
  padding: 20px;
}

.naslov-div {
  /* text-align: center; */
  font-size: 2rem;
  font-weight: bold;
  color: rgb(238,59,121);
  margin-bottom: 0.5rem;
}

.tekst {
  font-size: 1rem;
  color: black
}

.dugme {
  padding: 10px;
  text-align: center;
  background-color: rgb(238,59,121);
  color: white;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 10px;
  display: inline-block;

}

.dugme:hover {
  background-color: white;
  color: rgb(238,59,121);
}


/* .telefon-icon {  OVO NE RADI ZA VEC OBOJENE IKONICE
    color: green; 
  } */


/* OVO JOS PROMENI I PROVERI KAKO RADI */



/* ovo kosristi kad budes dodala medije ili tako nesto sto uvek treba da bude na stranici i kada se skroluje
  position: fixed; 
  Postavljamo na fiksnu poziciju 
  top: 50%;  Pomera dugme na vertikalnoj osi do sredine ekrana 
  left: 50%;  Pomera dugme na horizontalnoj osi do sredine ekrana 
  transform: translate(-50%, -50%);  Centrira dugme tačno u sredinu ekrana 
  */

.slika-container {
  position: relative;
}

.slika {
  width: 100%;
  height: auto;
  border-radius: 20px;
  transition: filter 0.3s ease;
}

.slika:hover {
  filter: brightness(70%);
}


.tekst-overlay a {
  /* color: white; */
  color: black; 
  text-decoration: none;
}

.tekst-overlay a:hover {
  color: rgb(238,59,121);
}


@media (min-width: 1300px){
.tekst-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(224, 219, 222, 0.725);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  transition: opacity 0.3s ease;
  opacity: 0;
}


.slika-container:hover .tekst-overlay {
  opacity: 1;
}

.tekst-overlay p {

  margin: 5px 0;
}
}

@media (max-width: 1300px) and (min-width: 600px) {
  .slika {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  .tekst-overlay a {
    font-size: 90%;
  }

  .slika-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Dodajte razmak između slika */
  }

  .slikeOrg {
    width: 100%; /* Postavite širinu slike na 100% kako bi se prilagodila veličini ekrana */
    border-radius: 20px; /* Prilagodite zaobljenost uglova slike */
    margin-bottom: 10px; /* Dodajte razmak između slike i teksta */
  }

  .tekst-container {
    text-align: center; /* Centrirajte tekst */
  }

  .organizatori {
    color: rgb(238,59,121); /* Postavite boju teksta */
    margin-bottom: 5px; /* Dodajte razmak između tekstualnih elemenata */
  }

  .tekst-overlay {
    text-align: left; 

  }
}


@media (max-width: 600px)  {
  .red {
    grid-template-columns: 1fr; /* Postavite da se slike prikazuju jedna ispod druge */
  }

  .prvi-red, .drugi-red {
    width: 100%; /* Povećajte širinu roditeljskog kontejnera da bi slike bile centrirane */
  }

  .prvi-red img, .drugi-red img {
    width: calc(100% - 10px); /* Smanjite širinu slika za 20 piksela kako biste ostavili prostor za marginu */
    margin-bottom: 10px; /* Smanjite razmak između slika */
  }

  .tekst-overlay {
    background-color: rgba(224, 219, 222, 0.925); /* Povećajte providnost pozadine za bolju čitljivost teksta */
    border-radius: 20px; /* Prilagodite zaobljenost uglova teksta overlay-a */
    padding: 15px; /* Povećajte razmak unutar teksta overlay-a */
    margin-top: 10px; /* Dodajte margine iznad teksta overlay-a da biste ga razdvojili od slika */
    width: calc(100% - 10px); /* Postavite širinu teksta overlay-a na istu širinu kao i slike */
    text-align: left; 
  }

  .tekst-overlay a {
    font-size: 90%;
  }

  .slika-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Dodajte razmak između slika */
  }

  .slikeOrg {
    width: 100%; /* Postavite širinu slike na 100% kako bi se prilagodila veličini ekrana */
    border-radius: 20px; /* Prilagodite zaobljenost uglova slike */
    margin-bottom: 10px; /* Dodajte razmak između slike i teksta */
  }

  .tekst-container {
    text-align: center; /* Centrirajte tekst */
  }

  .organizatori {
    color: rgb(238,59,121); /* Postavite boju teksta */
    margin-bottom: 5px; /* Dodajte razmak između tekstualnih elemenata */
  }
}