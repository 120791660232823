.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  padding-top: 0.7rem;
  background-color: rgb(224, 219, 222);
  /* box-shadow: 0 5px 15px rgba(184, 4, 151, 0.25);  */
  width: 98.5%;
  max-width: 98.5%;
  height: 80px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* position: fixed; */
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  top: 0;
  z-index: 1;
  border-bottom: 2px solid rgb(238, 59, 121);
  /* Dodavanje okvira samo na donju stranu */
  border-left: 2px solid rgb(238, 59, 121);
  /* Dodavanje okvira samo na levu stranu */
  border-right: 2px solid rgb(238, 59, 121);
  /* Dodavanje okvira samo na desnu stranu */
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0.2rem;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links {
  text-decoration: none;
  color: rgb(238, 59, 121);
  white-space: nowrap;
  font-weight: 500;
  flex-grow: 1;
  padding: 0.5rem 0.5rem;
}

.nav-links i {
  padding-right: 6px;
}

.nav-links:hover {
  /* background-color: rgb(174, 0, 108); */
  background-color: rgb(238, 59, 121);
  color: white;
  /* transition: all 0.2s ease-in-out; 
     font-size: 1.2rem;*/
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  border-radius: 10px;
  text-decoration: none;
}

.nav-item.submenu-open {
  /* background-color: rgb(174, 0, 108); */
  background-color: rgb(238, 59, 121);
  color: rgb(238, 59, 121);
  /* transition: all 0.2s ease-in-out; 
     font-size: 1.2rem;*/
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  border-radius: 10px;
  text-decoration: none;
}

.nav-item.submenu-open .nav-links {
  background-color: rgb(238, 59, 121);
  border-radius: 10px;
  color: white;
}

.burgerIkonice {
  color: #fff;
}

.fa-bars {
  color: white;
}
.fa-times {
  color: white;
}

.menu-icons {
  display: none;
}

.nav-links-white {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #fafafa;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
}

.nav-links-white:hover {
  background-color: rgb(238, 59, 121);
  transition: all 0.2s ease-in-out;
}

.NavLogo {
  width: 60px;
  height: auto;
  margin-right: auto;
  /* Pomeranje slike na kraj leve strane */
  margin-bottom: 5px;
}

.NavLogoTekst {
  width: 70px;
  height: auto;
  margin-bottom: 83px;
}

.submenu {
  position: absolute;
  top: 100%;
  background-color: rgb(224, 219, 222);
  text-decoration: none;
  display: none;
  padding: 0.5rem 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 1px 2px rgb(238, 59, 121);
  list-style-type: none;
  font-size: 1.1rem;
  min-width: 20vh;
  max-width: 40vh;
}

.has-submenu.submenu-open .submenu {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.submenu a {
  text-decoration: none;
  color: rgb(238, 59, 121);
  display: block;
  /* Da bi celo područje li elementa bilo interaktivno */
  padding: 0.5rem 0.5rem;
  /* transition: all 0.2s ease-in-out; */
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}

/* Hover styles for submenu links */
.submenu a:hover {
  background-color: rgb(238, 59, 121);
  color: white;
}

.close-icon::before {
  content: "\2715";
}

.menu-icon::before {
  content: "\2630";
}

.close-icon::before,
.menu-icon::before {
  font-style: normal;
  font-size: 24px;
  font-weight: bold;
  color: rgb(238, 59, 121);
}

@media screen and (max-width: 1199px) {
  .NavbarItems {
    z-index: 99;
  }

  .nav-item.submenu-open .nav-links {
    background-color: rgb(238, 59, 121);
    color: white;
  }

  .nav-menu {
    display: flex;
    /* display: none; */

    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 8px 32px 0 rgba(141, 24, 104, 0.37);
    background-color: rgb(224, 219, 222);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    /* z-index: 1; */
    transition: all 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0%;
  }

  /* .nav-links {
     display: block;
     widows: 100%;
     padding: 1rem;
     color: white;
   } */

  .nav-links:hover {
    background-color: rgb(238, 59, 121);
    color: white;
  }

  .menu-icons {
    display: block;
  }

  .nav-links a {
    display: block;
    widows: 100%;
    padding: 0.5rem;
    /* Postavite veličinu na 10% za mobilne uređaje */
  }

  .submenu a {
    display: block;
    widows: 100%;
    padding: 0.5rem;
    /* Postavite veličinu na 10% za mobilne uređaje */
    color: white;
  }

  .submenu a:hover {
    background-color: rgb(224, 219, 222);
    border-radius: 10px;
    color: rgb(238, 59, 121);
  }

  .submenu {
    position: static;
    /* Izbrišite "position: absolute;" kako bi se podmeni prikazivao kao glavni meni */
    background-color: rgb(238, 59, 121);
    /* Izbrišite pozadinsku boju podmenija ako je postavljena */
    box-shadow: none;
    /* Izbrišite senku ako je postavljena */
    border-radius: 0;
    /* Izbrišite zaobljenost rubova ako je postavljena */
  }
  .submenu {
    min-width: unset; /* Ponisti min-width */
    max-width: unset; /* Ponisti max-width */
  }
}

