.meetup-container {
  background-image: url(../../background.png);
  color: black;
  min-height: 100vh; /* Postavite visinu na 100vh */
  background-attachment: fixed; /* Dodajte fiksnu pozadinu */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.meetup-form {
  display: flex;
  border: 5px solid rgb(238, 59, 121);
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 85%;
  min-height: 80vh;
  margin: auto;
  flex-direction: column;
  padding: 10px;
}

.meetup-left {
  flex: 1;
  padding: 20px;
  /* border-right: 2px solid rgb(0, 191, 154); */
}

.form-grupa {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(238, 59, 121);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.combobox {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(238, 59, 121);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin-bottom: 20px; /* Dodajte razmak ispod combobox-a */
}

.form-grupa.radio-grupa {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.radio-red {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px; /* Dodajte razmak ispod redova */
}

.radio-label {
  width: calc(100% / 7);
}

.form-grupa.radio-grupa label {
  margin-right: 10px;
}

.form-grupa.radio-grupa .radio-red {
  display: flex;
  justify-content: space-between; /* Dodato svojstvo za centriranje */
  align-items: center;
  width: 100%; /* Dodato svojstvo za 100% širinu */
  text-align: center;
}

.form-grupa.radio-grupa input[type="radio"] {
  margin-right: 5px;
}

.radio-red {
  display: flex;
  flex-direction: row;
}

.meetup-prijave-tekst {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: left;
  text-align: center;
}

.message {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.success {
  color: rgb(238, 59, 121);
}

.error {
  color: red;
}

label {
  margin-bottom: 2vh;
}
