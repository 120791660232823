.vest-card {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  width: 95%;
  max-width: 100%;
  margin: 40px auto;
  padding: 10px;
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
  background-color: rgba(224, 219, 222, 0.725);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  /* justify-content: center; Poravnajte sadržaj vertikalno na sredinu */
  align-items: center; /* Poravnajte sadržaj horizontalno na sredinu */
}

body {
  background-image: url(../background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.vest-card img {
  width: auto;
  height: 450px;
  /* width: 50%; 
  height: auto; */
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
  /* order: 2;  */
  /* Promenite redosled slike i teksta */
}

.vest-card h2,
.vest-card p {
  width: 100%;
  margin: 0 auto; /* Dodajte margine iznad i ispod teksta */
  color: black;
  text-align: center; /* Poravnajte tekst na centar */
  padding-left: 5vh;
}

.vest-card button::after {
  position: absolute;
  top: 50%;
  right: 10px; /* Podešavanje horizontalnog položaja strelice */
  transform: translateY(-50%); /* Centriranje strelice vertikalno */
  font-size: 18px; /* Veličina strelice */
  opacity: 0; /* Inicijalno sakrijte strelicu */
  transition: opacity 0.3s ease; /* Dodajte efekat prelaska */
}

.vest-card button:hover {
  background-color: rgb(
    224,
    224,
    224
  ); /* Boja dugmeta kada pređete preko njega */
  color: rgb(238, 59, 121); /* Boja teksta kada pređete preko dugmeta */
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
}

/* Dodajte stilove za drugu vest */
.vest-card:nth-child(odd) {
  flex-direction: row-reverse; /* Obrnite redosled elemenata za parne vesti */
}

.vest-card:nth-child(odd) img {
  order: 1; /* Promenite redosled slike i teksta za parne vesti */
}

.vest-card:nth-child(odd) h2,
.vest-card:nth-child(odd) p {
  text-align: center; /* Tekst za parne vesti poravnajte desno */
}

/* Dodajte stilove za neparne vesti */
.vest-card:nth-child(even) h2,
.vest-card:nth-child(even) p {
  text-align: center;
}

/* Promenite redosled slike i teksta za parne vesti */
.vest-card:nth-child(even) {
  flex-direction: row-reverse;
}

/* Promenite poravnanje teksta za parne vesti */
.vest-card:nth-child(even) h2,
.vest-card:nth-child(even) p {
  text-align: center;
}

.vest-content {
  text-align: center; /* Poravnajte tekst u sredinu */
  margin-left: 20px;
  margin-right: 20px;
}

.vest-content h2 {
  margin-bottom: 30px; /* Dodajte razmak ispod naslova */
}

/* Responzivni stilovi */

@media screen and (max-width: 940px) and (min-width: 768px) {
  .vest-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .vest-card img {
    max-height: 30rem;

    width: auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .vest-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    /* max-width: 60%;
    min-height: 60%; */
  }

  .vest-card h2,
  .vest-card p {
    padding: 0;
    margin: 0; /* Uklonite margine za h2 i p */
  }

  .vest-card img {
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center; */

    max-height: 13rem;
    /* max-height: 200px; */
    width: auto;
    /* max-width: 100%; */
    /* min-height: 100%; */
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px; /* Razmak ispod slike */
  }
}
