.home-container {
  min-height: calc(100vh - 50px); /* Smanjuje min-visinu na visinu hedera */
  position: relative;
  box-sizing: border-box;
  padding-top: 0px;
}

.home {
  width: 100%;
}

.timer,
.BDW {
  /* background-image: url(./SlikeHome/background-vuk.png); */

  width: 100%;
  /* height: 100vh; */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.pozadina2 {
  /* background-image: url(./SlikeHome/background-vuk.png); */
  /* background-attachment: fixed; 
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: rgba(224, 219, 222, 0.725);
  padding: 4%;
}
/* 
.naslov {
  color: #fff;
  padding: 4rem;
  margin-top: 1rem;
  font-size: 2rem;
} */

.LogoHome {
  width: 25rem;
}

.datum {
  color: #fff;
  padding: 1rem;
  font-size: 2rem;
}

.svuda {
  color: #fff;
  padding: 1rem;
}

.prijava {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #fafafa;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1.2rem;
}

.linkKO {
  color: rgb(238, 59, 121);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.social-icons a {
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: rgb(238, 59, 121);
}

.footer-text p {
  font-size: 14px;
}

.razmakTakmicenje {
  padding: 0.5rem;
}

.razmakhome {
  height: 5vh;
}

.timerrazmak {
  height: 4vh;
}

.timer {
  color: white;
}

.pokrovitelj-naslov-tekst {
  color: rgb(238, 59, 121);
  font-size: 30px;
  text-align: center; /* Centriranje teksta */
  margin: auto;
}

.FONpocetna,
.MozzartPocetna {
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  transition: transform 0.3s; /* Dodajemo efekat tranzicije za transformaciju */
}

.FONpocetna:hover,
.MozzartPocetna:hover {
  transform: scale(1.1); /* Povećavamo sliku za 10% prilikom prelaska mišem */
}

.podrzali-container {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 3px solid rgb(238, 59, 121);
  border-radius: 10px;
  padding: 20px;
  /* Unutrašnji razmak za sadržaj */
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 1860px) {
  .LogoHome {
    max-width: 100%; /* Maksimalna širina logotipa je 100% širine kontejnera */
    height: auto; /* Visina će se automatski prilagoditi promenljivoj širini */
  }
}

@media screen and (max-width: 1000px) {
  .razmakhome {
    height: 5vh;
  }
  .timer {
    background-image: none;
  }
}

@media screen and (max-width: 630px) {
  .LogoHome {
    max-width: 80%;
    width: 80%;
  }
  .cestoPostavljenaPitanja {
    width: 90%;
    /*  margin: 1em auto !important; Postavljanje margine na 0 kako bi kontejner bio na levu stranu */
    margin: auto;
  }
  .razmakhome {
    height: 7vh;
  }
  .FONpocetna,
  .MozzartPocetna {
    width: 15rem;
  }
}
