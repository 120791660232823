.prijave-container {
  background-image: url(../background.png);
  color: black;
  padding: 5%;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Postavite visinu na 100vh */
  background-attachment: fixed; /* Dodajte fiksnu pozadinu */
}

.area {
  height: 10vh;
}

.prijave-form {
  display: flex;
  border: 5px solid rgb(238, 59, 121);
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 85%;
  min-height: 80vh;
  margin: auto;
  flex-direction: column;
}

.clan1 {
  display: flex;
  border: 2px solid rgb(238, 59, 121);
  /* min-width: 75%; */
  /* width: 90%; */
  min-height: 80vh;
  padding: 2rem;
  margin: auto;
  margin-bottom: 2rem;
  flex-direction: column;
}

.prijave-left {
  flex: 1;
  padding: 20px;
}

.form-grupa {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(238, 59, 121);
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
}

.combobox {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(238, 59, 121);
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  margin-bottom: 20px; /* Dodajte razmak ispod combobox-a */
}

.form-grupa.radio-grupa.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.radio-red {
  flex-wrap: wrap;
  justify-content: space-between;
}

.radio-label {
  width: calc(100% / 7);
}

.form-grupa.radio-grupa label {
  margin-right: 10px;
}

.form-grupa.radio-grupa .radio-red {
  display: flex;
  justify-content: space-between; /* Dodato svojstvo za centriranje */
  align-items: center;
  width: 100%; /* Dodato svojstvo za 100% širinu */
  text-align: center;
}

.form-grupa.radio-grupa input[type="radio"] {
  margin-right: 5px;
}

.prijave-tekst {
  display: flex;
  flex-direction: column;
  padding: 20px 55px;
  align-items: left;
  text-align: center;
}

.radio-red {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 993px) {
  .radio-label {
    width: calc(100% / 3) !important;
  }
  .prijave-form {
    width: 95%;
  }
}

@media screen and (max-width: 550px) {
  .radio-red {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  .radio-label {
    width: calc(100% / 2);
    font-weight: normal;
  }
}

.checkbox-red {
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkbox-group {
  margin-left: 2%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.checkbox-label input[type="checkbox"] {
  order: 2; /* Postavljamo redosled na drugi element */
  margin-right: 5px; /* Dodajemo mali razmak između checkboxa i teksta */
}

.checkbox-label label {
  order: 1; /* Postavljamo redosled na prvi element */
}

.checkbox-label {
  width: calc(100% / 7);
  margin-top: 10px;
  margin-bottom: 10px; /* Dodajemo razmak ispod svakog checkboxa */
  display: flex; /* Dodajemo fleksibilnost kako bi se checkbox i labela prikazali u istom redu */
  align-items: center; /* Poravnavamo checkboxeve i tekst */
}

.naslovStranice {
  margin-top: 4vh;
}

/* Dodajemo stilove za manje ekrane */
@media screen and (max-width: 550px) {
  .checkbox-label {
    width: calc(100%);
  }
  .checkbox-red {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
}
