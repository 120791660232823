/* Meetup.css */
.meetup {
  background-image: url(../background.png);
  min-height: 100vh; /* Postavite visinu na 100vh */
  background-attachment: fixed; /* Dodajte fiksnu pozadinu */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.naslovStranice {
  color: rgb(238, 59, 121);
}

.contentMeetup {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 5px solid rgb(238, 59, 121);
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 85%;
  min-height: 40vh;
  align-items: flex-start; /* Dodajte ovu liniju za postavljanje teksta uz levu ivicu */
}

.naslov-centar {
  display: flex;
  justify-content: center; /* Horizotalno centriranje */
  align-items: center; /* Vertikalno centriranje */
  width: 100%;
  color: rgb(238, 59, 121);
}

.naslov-levo {
  display: flex;
  width: 100%;
  color: rgb(238, 59, 121);
}

.naslov-centar h1 {
  text-align: center; /* Centriranje teksta unutar h1 elementa */
}

/* ... Prethodni stilovi ... */

.slideshow-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}

.slideshow-img {
  max-width: 100%;
  max-height: 100%;
  border: 5px solid rgb(238, 59, 121);
  flex: 1; /* Omogućava slici da popuni preostali prostor u containeru */
}

.prev,
.next {
  position: absolute;
  background-color: rgb(238, 59, 121);
  color: white;
  border: none;
  padding: 10px;
  height: 20%; /* Postavljanje visine dugmadi na 20% visine slideshow-a */
  width: 2rem; /* Postavljanje male širine dugmadi */
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.dugmePozicija {
  margin: 0 auto;
  margin-top: 3vh;
  width: 50%;
}

.noviDiv {
  /* Stil za novi div */
  border-top: 4px solid rgb(238, 59, 121);
  border-bottom: 4px solid rgb(238, 59, 121);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 10vh;
}

.predavac {
  /* Stil za pojedinačnog predavača */
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.predavac img {
  /* Stil za sliku predavača */
  max-width: 25%; /* Postavljamo maksimalno 25% širine roditeljskog diva */
  height: auto;
  max-height: 40vh;
  border: 2px solid rgb(238, 59, 121);
  margin-right: 10px;
  margin-left: 10%;
}

.predavacInfo {
  /* Stil za informacije o predavaču */
  width: 70%; /* Postavljamo 75% širine roditeljskog diva */
}

@media screen and (max-width: 780px) {
  .content {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .prev,
  .next {
    position: absolute;
    background-color: rgb(238, 59, 121);
    color: white;
    border: none;
    padding: 4px;
    height: 20%; /* Postavljanje visine dugmadi na 20% visine slideshow-a */
    width: 1.5rem; /* Postavljanje male širine dugmadi */
    cursor: pointer;
  }
  .dugmePozicija {
    margin-bottom: 10vh;
  }
  .predavac {
    flex-direction: column;
  }
  .predavacInfo {
    width: 90%;
  }
  .predavac img {
    max-width: 35vh;
  }
}

@media screen and (max-width: 500px) {
  .slideshow-container {
    height: auto;
  }
}
