.pojedinacna-vest {
  padding: 20px; /* Dodajte odstupanje unutar kontejnera */
}

.naslov-vest {
  text-align: center; /* Poravnajte naslov na centar */
  color: black; /* Postavite boju naslova */
}

.datum-autor-pozicija {
  text-align: center; /* Poravnajte datum i autora na centar */
}

.datum-autor {
  color: black;
  margin: 10px 0; /* Dodajte odstupanje između datuma i autora */
}

.datum-autor svg {
  margin-right: 5px; /* Dodajte prostor između ikone i teksta */
}

.pozadina-siva {
  display: flex;
  justify-content: center; /* Poravnajte sliku na centar */
}

.pozadina-slike {
  max-width: 50%; /* Postavite maksimalnu širinu slike na 100% */
  height: auto;
  border: 3px solid rgb(238, 59, 121); /* Dodajte okvir slici */
  border-radius: 10px; /* Zaoblite ivice okvira */
}

.sadrzaj {
  margin: 20px 40px; /* Postavite marginu od 20 piksela sa obe strane teksta */
  border: 3px solid rgb(238, 59, 121);
  border-radius: 10px;
  background-color: rgba(224, 219, 222, 0.725);
  padding: 30px;
}

.slikavideo {
  display: flex;
  flex-direction: column;
  align-items: center; /* Poravnajte sliku i tekst na centar */
  margin-top: 20px; /* Dodajte odstupanje između slike i teksta */
}

.nova-slika {
  margin-bottom: 20px; /* Dodajte odstupanje ispod slike */
}

.slika-vest {
  width: 100%; /* Postavite širinu slike na 100% */
}

.tekst-vest {
  text-align: justify; /* Poravnajte tekst na justificirano */
}

.video {
  margin-top: 20px; /* Dodajte odstupanje između teksta i videa */
}

.footer-razmak {
  margin-top: 20px; /* Dodajte odstupanje između elementa i donjeg ruba */
}

.pojedinacna-vest {
  max-width: 1500px; /* Postavite maksimalnu širinu kontejnera za vesti */
  margin: 0 auto; /* Postavite automatski margin da biste centrirali kontejner */
  padding-top: 10vh;
}

.ceoSadrzaj,
.dodatniTekst {
  text-align: justify; /* Poravnajte tekst na justificirano */
  margin: 0 20px; /* Postavite odstupanje od 20 piksela s leve i desne strane */
}

@media screen and (max-width: 780px) {
  .pozadina-slike {
    max-width: 90%; /* Postavite maksimalnu širinu slike na 100% */
    height: auto;
    border: 3px solid rgb(238, 59, 121); /* Dodajte okvir slici */
    border-radius: 10px; /* Zaoblite ivice okvira */
  }
}
