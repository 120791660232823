.timer {
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}

.brojevi {
  font-size: 10rem;
}

.slova {
  font-size: 1.5rem;
}

@media screen and (max-width: 630px) {
  .timer {
    font-size: 1.1rem;
  }
}
