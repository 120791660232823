/* Postavke za celu stranicu */
/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  } */

.left img,
.right img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 10px;
  width: 90%;
  border-radius: 8px;
}
.predavac img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 500px;
  width: 150%;
  border-radius: 50%;
}
.predavac1 img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 500px;
  width: 150%;
  border-radius: 50%;
}
.container .header h1 {
  font-family: Arial, sans-serif;
  font-size: 38px;
  color: black;
}
.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}
.slideshow-container5 {
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  display: flex;
  overflow: hidden;
  min-height: 80vh;
  justify-content: center;
  position: relative;
  width: 100%;
}

.slideshow-img {
  max-width: 100%;
  max-height: 100%;
  border: 5px solid rgb(238, 59, 121);
  flex: 1; /* Omogućava slici da popuni preostali prostor u containeru */
}

.prev,
.next {
  position: absolute;
  background-color: rgb(238, 59, 121);
  color: white;
  border: none;
  padding: 10px;
  height: 20%; /* Postavljanje visine dugmadi na 20% visine slideshow-a */
  width: 2rem; /* Postavljanje male širine dugmadi */
  cursor: pointer;
}

.prev {
  left: 10px;
}
.razmak {
  height: 20vh;
}
.next {
  right: 10px;
}
.slideshowslike {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 50%;
  width: 50%;
}

.naslov {
  color: black;
  text-align: center;
}
.naslov1 {
  color: rgb(238, 59, 121);
  text-align: center;
  font-size: 28px;
}
.razmak1 {
  height: 5vh;
}
.section {
  padding-right: 25px;
  padding-left: 25px;
  width: 100%;
}
.container .content .section .right h1 {
  font-family: Arial, sans-serif;
  font-size: 26px;
  color: black;
}
.container .content .section .left h1 {
  font-family: Arial, sans-serif;
  font-size: 26px;
  color: black;
}
.container .content .section .right p {
  font-family: Arial, sans-serif;
  font-size: 18px;
  text-align: justify;
  color: black;
}
.container .content .section .left p {
  font-family: Arial, sans-serif;
  font-size: 18px;
  text-align: justify;
  color: black;
}
.content {
  display: flex;
  border: 5px solid rgb(238, 59, 121);
  border-radius: 2vh;
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 120%;
  min-height: 80vh;

  text-align: center;
  flex-direction: column;
}
.container .predavac img {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.section .left img,
.section .right img {
  margin-bottom: 20px;
}

.container {
  max-width: 800px;
  margin: 500px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

/* Stilizacija zaglavlja */
.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 32px;
  color: #333;
}

/* Stilizacija sekcija */
.section {
  display: flex;
  margin-bottom: 40px;
}

/* Stilizacija leve i desne strane sekcije */
.left,
.right {
  flex: 1;
}

@media screen and (max-width: 1300px) {
  .section {
    flex-direction: column; /* prikaz sekcija na vertikalni na manjim ekranima */
  }

  .left,
  .right {
    word-wrap: break-word;
    text-align: center;
    margin: 0; /* sklanja bočni razmak na manjim ekranima */
    width: 100%; /* popunjava širinu ekrana na manjim ekranima */
  }
  .naslov1 {
    text-align: center;
  }
  .naslovh {
    text-align: left;
    position: relative;
  }

  .left h1,
  .right h1 {
    font-size: 20px; /* smanjuje veličinu naslova na manjim ekranima */
  }

  .left p,
  .right p {
    font-size: 14px; /* smanjuje veličinu teksta na manjim ekranima */
    line-height: 1.5; /* povecava razmak između redova */
  }

  .slideshowslike {
    max-height: 75%;
    width: 100%;
  }

  .container .predavac img,
  .container .predavac1 img {
    height: auto; /* menja visinu slike na automatsko na manjim ekranima */
  }

  .slideshow-container5 {
    min-height: 40vh;
  }
  
}

.left img,
.right img {
  max-width: 100%;
  height: auto;
}

.left h1,
.right h1 {
  font-size: 24px;
  color:rgb(238, 59, 121);
}

.left p,
.right p {
  font-size: 20px;
  color: black;
  
}

/* Stilizacija specifična za levi deo sekcije */
.left {
  margin-right: 20px;
}

/* Stilizacija specifična za desni deo sekcije */
.right {
  margin-left: 20px;
}

/* Stilizacija slika */
/* img {
    display: block;
    margin: 0 auto;
  } */
