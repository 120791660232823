/* Kontakt.css */
.contact-container {
  background-image: url(../background.png);
  color: black;
  padding: 5%;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Postavite visinu na 100vh */
  background-attachment: fixed; /* Dodajte fiksnu pozadinu */
}

.contact-form {
  display: flex;
  border: 5px solid rgb(238, 59, 121);
  background-color: rgba(224, 219, 222, 0.725);
  width: 85%;
  min-width: 85%;
  min-height: 80vh;
  margin: auto;
  flex-direction: column;
}

.form-left {
  flex: 1;
  padding: 20px;
}

.form-right {
  flex: 1;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(238, 59, 121);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

button {
  width: 100%;
  padding: 10px;
  background-color: rgb(238, 59, 121);
  color: black;
  border: none;
  cursor: pointer;
}
.map-container {
  height: 300px;
  margin-bottom: 20px;
}

.contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-info button {
  margin-top: 10px;
}

.contact-info svg {
  margin-right: 10px;
}

p {
  margin-top: 20px;
}

.txtporuka {
  height: 25vh;
}

.poziv {
  color: black;
  text-decoration: none;
}
