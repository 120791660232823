/* Footer stilovi */
.footer {
  background-color: rgba(224, 219, 222); /* Boja pozadine footera */
  color: rgb(238, 59, 121); /* Boja teksta u footeru */
  text-align: center;
  padding: 10px 0; /* Unutarnji prostor (padding) na vrhu i dnu footera */
  position: fixed; /* Fiksirajte footer na dnu */
  width: 100%; /* Širina footera 100% ekrana */
  bottom: 0; /* Postavite footer na dno ekrana */
  z-index: 999; /* Z-indeks kako bi footer bio iznad ostalih elemenata */
  height: 17.5vh; /* Visina footera */
  display: flex; /* Koristite Flexbox za raspoređivanje sadržaja */
  flex-direction: column; /* Prikazuje elemente u koloni */
  justify-content: center; /* Centrirajte sadržaj vertikalno */
  align-items: center; /* Centrirajte sadržaj horizontalno */
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  transition: visibility 0s, opacity 0.3s, transform 0.3s;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.footer.visible {
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: visibility 0s, opacity 0.3s, transform 0.3s;
}

/* Stilovi za sadržaj footera */
.footer-content {
  display: flex; /* Koristite Flexbox za raspoređivanje sadržaja */
  flex-direction: column; /* Prikazuje elemente u koloni */
  justify-content: space-between; /* Rasporedite sadržaj ravnomerno sa obe strane */
  align-items: center; /* Centrirajte sadržaj vertikalno */
  flex-grow: 1; /* Omogućava footer-content da raste kako bi zauzeo dostupan prostor */
  padding: 5px;
}

/* Stilovi za ikone društvenih mreža */
.social-icons a {
  color: rgb(238, 59, 121); /* Boja ikona društvenih mreža */
  margin: 0 10px; /* Margina između ikona */
  font-size: 35px; /* Veličina ikona društvenih mreža */
  text-decoration: none; /* Uklonite podvlačenje linkova */
}

/* Stilovi za tekst u footeru */
.footer-text p {
  margin: 5px 0; /* Margina oko teksta */
}

.srce {
  color: white;
}

.srce:hover {
  color: red;
}

body {
  padding-bottom: 150px; /* Dodatni padding na dnu tela dokumenta */
}


@media screen and (max-width: 600px) {
  .footer {
    padding: 10px 0;
    height: auto;
    width: 100%;
  }
  .footer-text p {
    margin: 10px 0;
  }
}

@media screen and (max-width: 375px) {
  .footer {
    height: 14vh;
  }
}

@media screen and (max-height: 700px) {
  .footer {
    padding: 10px 0; /* Unutarnji prostor (padding) na vrhu i dnu footera */
    height: auto; /* Postavite visinu na "auto" kako bi se prilagodila sadržaju */
    width: 100%;
  }
  .footer-text p {
    margin: 10px 0; /* Margina oko teksta */
    padding-right: 0rem;
  }
}

@media screen and (min-height: 1170px) and (max-height: 1400px) {
  .footer {
    padding: 5px 0; /* Unutarnji prostor (padding) na vrhu i dnu footera */
   /* Postavite visinu na "auto" kako bi se prilagodila sadržaju */
    width: 100%;
    padding-bottom: 40px;
    padding-top: 60px;
    height: 3vh; 
  }
  .footer-text p {
    margin: 2px 0; /* Margina oko teksta */
    padding-right: 0rem;
  }
  body {
    padding-bottom: 20px; /* Dodatni padding na dnu tela dokumenta */
  }
}
