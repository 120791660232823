/* Partneri.css */
.partneri {
  /* background-image: url(../background.png); */
  /* min-height: 100vh;  Postavite visinu na 100vh 
    background-attachment: fixed;  Dodajte fiksnu pozadinu 
    background-size: cover;
    background-repeat: no-repeat; */

  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.partneri-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 3px solid rgb(238, 59, 121);
  border-radius: 10px;
  padding: 20px;
  /* Unutrašnji razmak za sadržaj */
  background-color: rgba(224, 219, 222, 0.725);
  min-width: 85%;
  width: 85%;
}

.partner {
  width: calc(33.33% - 20px);
  margin-bottom: 2rem;
  min-width: 100%;
}

.partner-slika {
  width: 20rem;
  max-width: 250px;
  max-height: 250px;
  margin: 20px;
  transition: transform 0.3s ease-in-out;
  /* Dodajte tranziciju za transformaciju */
}

.partner-slika:hover {
  transform: scale(1.1);
  /* Povećajte sliku na hover za 10% */
}

.partner-naslov {
  margin-bottom: 1rem;
  font-size: 2rem;
  margin-top: 1rem;
  padding: 10px;
  color: rgb(238, 59, 121);
}

.naslovStranicePartneri {
  font-size: 3rem;

  width: 100%;
  margin-bottom: 1vh;
  margin-top: 3.5vh;
}

@media screen and (min-width: 1600px) {
  .partneri-container {
    width: 75%;
  }
}

@media screen and (max-width: 933px) {
  .partner {
    width: 100%;
  }

  .partneri-container {
    margin-bottom: 40%;
  }

  .partner-content {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    /* Margin: 0 za uklanjanje margin za slike */
  }

  .partner-slika {
    margin: 0;
    margin-bottom: 10px;
    /* Razmak između slike i teksta */
  }

  .partner-slika {
    width: 90%;
  }
}
