.card {
font-size: 1.2rem;
/* text-align: center; */
margin: 30px;
  /* display: flex;
  flex-direction: column;
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 300px;
  margin: 20px;
   background-color: rgb(238, 59, 121);
  color: #fff; */
}

.card:hover {
  transform: scale(1.05); /* Increase the card size slightly on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1); /* Add a shadow on hover */
  background-color:  rgba(224, 219, 222, 0.725);
  color: rgb(238, 59, 121);
}

.card-image {
  max-width: 100%;
  height: auto;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.card-title {
  color: rgb(238, 59, 121);
  font-size: 1.45rem;
  font-weight: bold;
  margin: 0;
}

.card-title-nagrade {
  font-size: 1.75rem;
  font-weight: bold;
}

.card-text {
  margin-top: 8px;
}

.card-text-nagrade {
  color: rgb(238, 59, 121);
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0;
}

.button-text {
  white-space: nowrap;
  background-color: rgb(238, 59, 121);
  border-radius: 3px;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}

/* NAGRADE */

.cardNagrade {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 300px;
  margin: 20px;
  background-color: rgba(224, 219, 222, 0.725); 
}

.cardNagrade:hover {
  border: 1px solid  rgb(238, 59, 121);
  transform: scale(1.05); /* Increase the card size slightly on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1); /* Add a shadow on hover */
  background-color: rgba(224, 219, 222, 0.468); /* Change background color on hover */
}

.cardNagrade-container {
  display: flex;
  justify-content: space-around; /* Adjust as needed: space-between, flex-start, flex-end, etc. */
  flex-wrap: wrap; /* Allow cards to wrap to the next row if there's not enough space */
  
}

/* Pokrovitelji */

.pokroviteljiCard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 300px;
  margin: 20px;
  background-color: #fff;
  height: 30%;
}

.pokrovitelji {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Adjust as needed: space-between, flex-start, flex-end, etc. */
  flex-wrap: wrap; /* Allow cards to wrap to the next row if there's not enough space */
}

.pokrovitelj-naslov {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Adjust as needed: space-between, flex-start, flex-end, etc. */
  flex-wrap: wrap;
}

/* Pitanja */

/* Stilovi za Pitanja komponentu */
.cestoPostavljenaPitanja {
  text-align: left; /* Poravnanje na levu stranu */
  border: 3px solid rgb(238, 59, 121);/* Okvir sa borderom */
  padding: 20px; /* Unutarnji razmak */
  border-radius: 10px; /* Zaobljeni uglovi */
  max-width: 100%; /* Maksimalna širina kontejnera */
  margin:auto; /* Postavljanje margine na 0 kako bi kontejner bio na levu stranu */
 /* background-image: url("../../background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;  */
  background-color: rgba(224, 219, 222, 0.725);
}

.pitanje {
  padding-top: 15px;
  margin-bottom: 15px; /* Razmak između pitanja */
}

/* Stilovi za naslov pitanja */
.pitanjaNaslov {
  text-align: center;
  font-size: 1.8rem; /* Veći font za naslov */
  margin-bottom: 0.5rem; /* Mali razmak ispod naslova */
  color: rgb(238, 59, 121);
}

.glavniTekst {
  font-size: 1.8rem; /* Veći font za naslov */
  margin-bottom: 0.5rem; /* Mali razmak ispod naslova */
  color: rgb(238, 59, 121);
}

.skriveniTekst {
  color: black;
}

.dodatnoKO {
  color: black;
}

.button-text {
  padding: 10px;
  text-align: center;
  background-color:rgb(224, 219, 222); 
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
  color:  rgb(238, 59, 121);
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: auto;
  display: inline-block;
 /*  margin-right: auto; Pomerite dugme uz desnu ivicu diva */
}

.button-text:hover {
  background-color: rgb(238, 59, 121); 
  color: white;
  border: 3px solid rgb(238, 59, 121);
  border-radius: 5px;
}

.button-container {
  display: flex;
  flex-direction: column; /* Postavite dugmad jedno ispod drugog */
  align-items: center; /* Centrirajte dugmad horizontalno */
  margin-top: 10px; /* Razmak između dugmadi */
 
  margin: 0 auto;
 
  width: 50%;
  
 }


