.wrapper {
  margin-top: 30vh;
  text-align: center;
}

.uizradi {
  font-size: 2.5em;
  color: #333;
}

.contentProgram {
  display: flex;
  border: 5px solid rgb(238, 59, 121);
  border-radius: 1rem;
  background-color: rgba(224, 219, 222, 0.725);
  width: 85%;
  min-width: 85%;
  min-height: 80vh;
  margin: auto;
  flex-direction: column;
  margin-top: 5vh;
  margin-bottom: 8vh;
}

@media screen and (max-height: 700px) {

  .uizradi {
    font-size: 2rem;
    color: #333;
  }
}